<template>
    <main>
        <section>
            <div>

                <h1>
                    {{ page.longtitle }}
                </h1>

                <div v-html="page.content" />

            </div>
        </section>

        <slot name="footer"></slot>
    </main>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin';

export default {
	mixins: [
		pageMixin,
	],
}
</script>
