<template>
    <main class="home">

        <hero-section :page="page" />


        <section class="white bookings">
            <div class="content" v-html="page.variables.agendaText" />

            <aside class="agenda widget">
                <h2>
                    Vandaag
                </h2>

                <agenda-date v-if="Object.keys(agendaItems).length > 0" :date="new Date(date)"
                    :data-n="new Date(date).getDay()" :options="agendaItems[month].options" :showDate="false" />

                <router-link :to="{ name: 'Agenda' }" class="button">
                    Bekijk de gehele agenda
                </router-link>
            </aside>
        </section>


        <section v-if="tattoos.length > 0">
            <div class="grid">
                <router-link v-for="(t, id) in latestTattoos" class="tattoo" :to="{ name: 'Image', params: { id: id} }"
                    :key="id">
                    <picture>
                        <img :src="t[640]" alt="" />
                    </picture>
                </router-link>

                <router-link :to="{ name: 'TattooGallery' }" class="button">
                    Bekijk alle foto's
                </router-link>
            </div>
        </section>


        <section class="white floating-image">

            <div class="image right">
                <div class="wrapper">
                    <app-figure class="grayscale" :img="page.variables.image" />
                </div>
            </div>

            <div class="content">
                <div v-html="page.content" />

                <div class="toolbar">
                    <router-link :to="{ path: '/agenda' }" class="button">
                        Kijk in de agenda
                    </router-link>

                    <router-link :to="{ path: '/contact' }" class="button secondary">
                        Neem contact op
                    </router-link>
                </div>
            </div>

        </section>


        <section class="split">
            <div class="content tertiary">
                <div v-html="page.variables.textPainting" />

                <router-link :to="{ name: 'PaintingOverview'}" class="button">
                    Bekijk het schilderportfolio
                </router-link>
            </div>

            <div class="content joyart">
                <div v-html="page.variables.textJoyArt" />

                <router-link :to="{ name: 'JoyArtOverview' }" class="button">
                    Bekijk het JoyArt portfolio
                </router-link>
            </div>
        </section>

        <slot name="footer"></slot>

    </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { pageMixin } from '@/mixins/pageMixin';

import AgendaDate from '@/components/AgendaDate.vue';
import AppFigure from '@/components/AppFigure.vue';
import HeroSection from '@/components/HeroSection.vue';


var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
        AgendaDate,
		AppFigure,
		HeroSection,
	},


	data()
	{
		return {
			ctas: [
				[
					{
						route: 'TattooOverview',
						text: 'Bekijk het tattoo portfolio',
					},
				],
				[
					{
						route: 'PaintingOverview',
						text: 'Bekijk het schilder portfolio',
						color: 'black',
					},
					{
						route: 'JoyArtOverview',
						text: 'Bekijk het JoyArt portfolio',
						color: 'joyart',
					},
				],
			],
		};
	},


	computed: {
		...mapGetters([
            'agendaItems',
			'categories',
			'paintings',
			'tattoos',
		]),


        date()
        {
            let date = new Date(),
                day = date.getDate().toString();
            
            if (day.length < 2) day = '0' + day;

            return vm.month.replace('_', '-') + '-' + day;
        },


        latestTattoos()
        {
            let tats = [];
            
            for (let i = 0; i < 6; i++)
            {
                tats.push(vm.tattoos[i]);
            }

            return tats;
        },


        month()
        {
            let d = new Date(),
            m = d.getMonth() + 1,
            y = d.getFullYear();

            return m < 10 ? `${y}_0${m}` : `${y}_${m}`;
        },


		paintingPreviews()
		{
			let pp = {};

			for (let i = vm.categories.length - 1; i >= 0 ; i--)
			{
				let cat = vm.categories[i];


				if (cat.type !== 'schilderijen') continue;


				for (let j = 0; j < vm.pages.length; j++)
				{
					let p = vm.pages[j];

					if (p.template == 3 && p.alias == cat.alias && p.parent == 9)
					{
						pp[cat.alias] = {
							image: p.variables.image,
							title: cat.title,
						};
					}
				}
			}

			let previews = [];
			let keys = Object.keys(pp);
			keys.reverse();

			for (let i = 0; i < keys.length; i++)
			{
				let preview = pp[keys[i]];
				preview.alias = keys[i];
				previews.push(preview);
			}

			return previews;
		},


		tattooPreviews()
		{
			let tp = {};

			for (let i = vm.categories.length - 1; i >= 0 ; i--)
			{
				let cat = vm.categories[i];


				if (cat.type !== 'tattoos') continue;


				for (let j = 0; j < vm.pages.length; j++)
				{
					let p = vm.pages[j];

					if (p.template == 3 && p.alias == cat.alias && p.parent == 8)
					{
						tp[cat.alias] = {
							image: p.variables.image,
							title: cat.title,
						};
					}
				}
			}

			return tp;
		},
	},


    methods: {
        ...mapActions([
			'getAgendaItems',
		]),
    },


	created()
	{
		vm = this;

        vm.getAgendaItems(vm.month);
	},
}
</script>
