<template>
    <transition-group name="fade">

        <article v-if="loaded" :class="locked ? 'locked' : ''" key="article">

            <app-header :showNav="showNav" :isPaintings="isPaintings" @locked="lockScroll" />


            <div v-if="showAlert" class="alert">
                <strong>
                    Let op:
                </strong>

                Wizzard Tattoo is per augustus verhuisd naar

                <address>
                    Eiland 16, 4528 KH St. Kruis
                </address>
            </div>


            <div id="smooth-wrapper">

                <router-view v-slot="{ Component }">
                    <transition mode="out-in" :css="false" @enter="onEnter" @leave="onLeave">

                        <component id="smooth-content" name="page" :is="Component" :key="routerViewKey" :class="mainClass"
                            @updated="onUpdate">

                            <template v-slot:footer>
                                <app-footer />
                            </template>

                        </component>

                    </transition>
                </router-view>

            </div>


            <transition name="fade" mode="out-in">
                <product :class="modalClass" v-if="$route.meta.showModal" />
            </transition>
            
        </article>


        <loader key="loader" v-else />

    </transition-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import gsap from 'gsap'
import ScrollSmoother from 'gsap/ScrollSmoother'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import Loader from '@/components/LoaderElement.vue';
import Product from '@/components/ProductModal.vue';

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
		Loader,
        Product,
	},


	data()
	{
		return {
			locked: false,
			loadedPaintings: false,
			loadedTattoos: false,
            showNav: false,
            smoother: null,
			transition: 'fade',
            transitionComplete: null,
        };
	},


	computed: {
		...mapGetters([
			'pages',
		]),


		isPaintings()
		{
			return vm.$route.name.toLowerCase().indexOf('painting') >= 0;
		},


		loaded()
		{
			let l = true;

			if (vm.pages.length === 0) l = false;
			else if (!vm.loadedPaintings) l = false;
			else if (!vm.loadedTattoos) l = false;

			return l;
		},


		mainClass()
		{
			let name = vm.$route.name.toLowerCase();

			if (name.indexOf('joyart') >= 0)
			{
				return 'joyart';
			}
			else if (name.indexOf('painting') >= 0)
			{
				return 'tertiary';
			}

			return 'primary';
		},


        modalClass()
        {
            return vm.$route.name.toLowerCase();
        },


		routerViewKey()
		{
			let rvk = vm.$route.path;

			if (['Painting', 'Image'].indexOf(vm.$route.name) >= 0)
			{
				let splitter = '/';
				let parts = rvk.split(splitter);
				parts.pop();
				rvk = parts.join(splitter);
			}

			return rvk;
		},


        showAlert()
        {
            let date = new Date();

            return date.getFullYear() <= 2024;
        },
	},


	methods: {
		...mapActions([
			'_initialize',
			'_lazyImages',
		]),


		lockCheck()
		{
			vm.locked = ['Image', 'Painting'].indexOf(vm.$route.name) >= 0;
		},


		lockScroll(val)
		{
			vm.showNav = val;
			vm.locked = val;
		},


        onEnter(el, done)
        {
            let alpha = vm.transition == 'project' ? 1 : 0;

            gsap.set(el, { autoAlpha: alpha });
            gsap
                .timeline({
                    paused: true,
                    onComplete() {
                        vm.transitionComplete = true;
                        done();
                    },
                })
                .to(el, { autoAlpha: 1 })
                .play();
            
            // Create the new ScrollSmoother
            vm.setupScrollSmoother();
        },


        onLeave (el, done)
        {
            vm.transitionComplete = false;
            
            let alpha = vm.transition == 'project' ? 1 : 0;

            gsap
                .timeline({ paused: true, onComplete: done })
                .to(el, { autoAlpha: alpha })
                .play();
                    
            // Kill all ScrollTriggers
            ScrollTrigger.killAll();
            vm.smoother = null;
        },


        onUpdate()
        {
            vm.$nextTick(() => {
                vm.setupScrollSmoother();
            })
        },


        setupScrollSmoother()
        {
            // Create the smooth scroller FIRST!
            if (ScrollTrigger.isTouch !== 1) {
                vm.smoother = ScrollSmoother.create({
                    smooth: 1,
                    effects: true,
                    normalizeScroll: true,
                    onUpdate: (self) => {
                        vm.scrollY = self.scrollTop();
                    }
                });
            }
        },
	},


	created()
	{
		vm = this;

        ScrollTrigger.normalizeScroll(true);

		vm.lockCheck();


		// vm.getUser();
		// vm.getStructure();
		// vm.loadPages();
		// vm.getCategories();
		// vm.getImages()
		// .then(() => {
		// 	vm.loadedTattoos = true;
		// });
		// vm.getPaintings()
		// .then(() => {
		// 	vm.loadedPaintings = true;
		// });
		vm._initialize()
		.then(() => {
			vm._lazyImages()
			.then(() => {
				vm.loadedPaintings = true;
				vm.loadedTattoos = true;
			});
		});
	},


	watch: {
		$route(to, from)
		{
			vm.lockCheck();

			let modalRoutes = ['Image', 'Painting', 'PaintingGallery', 'TattooGallery'];

			vm.transition = modalRoutes.indexOf(to.name) >= 0 && modalRoutes.indexOf(from.name) >= 0 ? 'none' : 'fade';

            // Update document title
            // let separator = ' | ',
            //     parts = document.title.split(separator),
            //     name = parts.length > 1 ? parts[parts.length - 1] : 'Wizzard Tattoo';


            // document.title = [p.seotitle, name].join(separator);

			vm.showNav = false;
		},
	}
}
</script>

<style lang="scss">

	@import "assets/css/all.min.css";
	@import "assets/scss/app.scss";

</style>
