<template>
	<div :class="classes">
		<p v-html="message" />
	</div>
</template>

<script>
var vm;

export default {
	props: {
		success: Boolean,
	},


	computed: {
		classes()
		{
			let cl = ['feedback'];

			if (vm.success)
			{
				cl.push('success');
			}
			else
			{
				cl.push('error');
			}

			return cl.join(' ');
		},


		message()
		{
			return vm.success ? 'Bedankt voor je bericht. Er wordt z.s.m. contact opgenomen.' : 'Er ging helaas iets mis. Probeer het a.u.b. nogmaals, of stuur anders een directe e-mail naar <a href="mailto:info@wizzardtattoo.nl">info@wizzardtattoo.nl</a>';
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
