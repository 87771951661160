<template>
    <main>

        <section class="agenda">
            <h1>
                Agenda
            </h1>

            <div v-if="page.hasOwnProperty('content') && page.content.length > 0" v-html="page.content" />

            <contact-toolbar />


            <div class="agenda-container" :key="agendaKey">

                <h3 role="pagination">
                    <i :class="paginationClasses('prev')" @click="setMonth('prev')"></i>

                    <transition name="fade-in-up" mode="out-in">
                        <span :key="month">
                            {{ monthName }}
                        </span>
                    </transition>

                    <i :class="paginationClasses('next')" @click="setMonth('next')"></i>
                </h3>


                <transition-group name="fade" mode="out-in" tag="div" class="agenda full">

                    <div v-for="d in days" class="title" :key="`${agendaKey}_${d}`">
                        {{ d }}
                    </div>

                    <div v-for="d in oldDates" class="day disabled" :key="month + '_d_' + d">
                        <div class="date">
                            {{ d }}
                        </div>
                    </div>

                    <agenda-date v-for="n in monthAgenda.days" :key="[month, n].join('_')" :date="getDateProp(n)"
                        :options="monthAgenda.options" :data-n="n" />

                    <div v-for="d in extraDates" class="day disabled" :key="month + '_e_' + d">
                        <div class="date">
                            {{ d }}
                        </div>
                    </div>

                </transition-group>

            </div>


            <contact-toolbar />

        </section>
        
        <slot name="footer"></slot>
        
    </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loggedInMixin } from '../mixins/loggedInMixin';
import { pageMixin } from '../mixins/pageMixin';

import AgendaDate from '../components/AgendaDate.vue';
import ContactToolbar from '../components/ContactToolbar.vue';

var vm;

export default {
    mixins: [
        loggedInMixin,
        pageMixin,
    ],


    components: {
        AgendaDate,
        ContactToolbar,
    },


    data() {
        return {
            days: [
                'zondag',
                'maandag',
                'dinsdag',
                'woensdag',
                'donderdag',
                'vrijdag',
                'zaterdag',
            ],
            month: false,
            monthModifierMax: 5,
        };
    },

    computed: {
        ...mapGetters([
            'agendaItems',
            'monthModifier',
        ]),


        agendaKey()
        {
            return [
                vm.month,
                Object.keys(vm.monthAgenda).length > 0 ? vm.monthAgenda.dates.length : 0,
            ].join('_');
        },


        extraDates() {
            let dates = [];

            let date = new Date(vm.month.replace('_', '-') + '-' + vm.monthAgenda.days);

            if (date.getDay() >= 2) {
                for (let i = 1; i < 7 - date.getDay(); i++) {
                    dates.push(vm.getDateString(vm.getDate(date, i)));
                }
            }

            return dates;
        },


        monthAgenda() {
            return Object.prototype.hasOwnProperty.call(vm.agendaItems, vm.month) ? vm.agendaItems[vm.month] : {};
        },


        monthName() {
            let date = new Date(vm.month.replace('_', '-') + '-01');

            return date.toLocaleDateString('nl-NL', {
                month: 'long',
                year: 'numeric',
            });
        },


        oldDates() {
            let dates = [];

            let date = new Date(vm.startDate)

            for (let i = date.getDay(); i > 0; i--) {
                dates.push(vm.getDateString(vm.getDate(date, i * -1)));
            }

            return dates;
        },


        startDate() {

            return new Date(vm.month.replace('_', '-') + '-01');
        },
    },


    methods: {
        ...mapActions([
            'getAgendaItems',
            'updateMonthModifier',
        ]),


        getAppointments(n) {
            let apts = [];

            let date = vm.getDateProp(n);
            let key = date.toLocaleDateString('nl-NL', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });

            key = key.split('-').reverse().join('-');

            for (let i = 0; i < vm.monthAgenda.dates.length; i++) {
                let d = vm.monthAgenda.dates[i];

                if (d.date == key) {
                    apts.push(d);
                }
            }

            return apts;
        },


        getDate(baseDate, modifier) {
            let date = new Date(baseDate);

            if (typeof (modifier) == 'undefined') modifier = 0;

            date.setDate(date.getDate() + modifier);

            return date;
        },


        getDateProp(n) {
            // Add a leading 0 if needed
            if (n < 10) n = '0' + n;

            let dateString = vm.month.replace('_', '-') + '-' + n;


            return vm.getDate(dateString);
        },


        getDateString(date) {
            return date.toLocaleDateString('nl-NL', {
                day: 'numeric',
                month: 'long',
            });
        },


        getMonth(input) {
            let date = typeof (input) == 'undefined' ? new Date() : new Date(input.year, input.month),
                month = date.getMonth() + 1,
                year = date.getFullYear();

            month += vm.monthModifier;

            if (month > 12) {
                month -= 12;
                year++;
            }

            let m = month.toString();

            if (m.length < 2) {
                m = '0' + month;
            }

            let output = year + '_' + m;
            vm.month = output;

            return output;
        },


        paginationClasses(type) {
            let cl = ['fas', 'clickable'];


            if (type === 'prev') {
                cl.push('fa-angle-left');

                if (vm.monthModifier === 0) {
                    cl.push('disabled');
                }
            }
            else if (type === 'next') {
                cl.push('fa-angle-right');

                if (vm.monthModifier === vm.monthModifierMax) {
                    cl.push('disabled');
                }
            }


            return cl.join(' ');
        },


        setMonth(direction) {
            if (direction == 'next' && vm.monthModifier < vm.monthModifierMax) {
                vm.updateMonthModifier(1).then(vm.getMonth())
            }
            else if (direction == 'prev' && vm.monthModifier > 0) {
                vm.updateMonthModifier(-1).then(vm.getMonth())
            }
        },
    },


    created() {
        vm = this;

        vm.getMonth();
    },


    watch: {
        month(to) {
            vm.getAgendaItems(to)
        },
    }
}
</script>