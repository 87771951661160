<template>
    <main class="contact">
        <section class="columns">

            <div>
                <h1>
                    {{ page.longtitle }}
                </h1>

                <div v-html="page.content" />
            </div>

        </section>


        <section class="columns white">

            <div>
                <h2>
                    Wizzard Tattoo
                </h2>

                <ul class="icon-list">
                    <li v-if="!onlyStKruis">
                        <i class="fas fa-map-marker-alt"></i>

                        {{ vars.address }}

                        <em v-html="getDays(0)" />

                        <br />

                        {{ vars.postcode }}
                        {{ vars.city }}
                    </li>

                    <li>
                        <i class="fas fa-map-marker-alt"></i>

                        Eiland 16

                        <em v-if="!onlyStKruis" v-html="getDays(1) " />

                        <br />

                        4528 KH
                        Sint Kruis
                    </li>

                    <li>
                        <i class="fab fa-whatsapp"></i>

                        {{ vars.phone }}
                    </li>

                    <li>
                        <i class="fas fa-at"></i>

                        {{ vars.email }}
                    </li>

                    <li>
                        <a :href="'mailto:' + vars.email" class="button tertiary">
                            Stuur een e-mail
                        </a>
                    </li>
                </ul>
            </div>

            <div class="box secondary">
                <h2>
                    Contact formulier
                </h2>


                <transition name="fade" mode="out-in">

                    <loader v-if="processing" />

                </transition>

                <feedback v-if="success === false" :success="success" />

                <ul class="form" v-if="!success">
                    <li>
                        <input type="text" v-model="contact.name" placeholder="Naam..." />
                    </li>

                    <li>
                        <input type="email" v-model="contact.email" placeholder="E-mailadres..." />
                    </li>

                    <li>
                        <textarea v-model="contact.message" placeholder="Bericht..." />
                    </li>

                    <li>
                        <label for="files">
                            Voeg afbeeldingen toe (optioneel)
                        </label>

                        <input type="file" id="files" ref="files" multiple @change="handleFilesChange" />
                    </li>

                    <li>
                        <button class="tertiary" :disabled="disabled" @click.prevent="submit">
                            Versturen
                        </button>
                    </li>
                </ul>

                <feedback v-else :success="success" />

            </div>

        </section>

        <slot name="footer"></slot>
    </main>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import { pageMixin } from '../mixins/pageMixin';

import Feedback from '../components/FeedbackSection.vue';
import Loader from '../components/LoaderElement.vue';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
		Feedback,
		Loader,
	},


	data()
	{
		return {
			contact: {
				email: '',
				name: '',
				message: '',
			},
			files: '',
			submitted: false,
			success: null,
			processed: false,
		};
	},


	computed: {
		...mapGetters([
			'base',
		]),


		disabled()
		{
			let keys = Object.keys(vm.contact);

			for (let i = 0; i < keys.length; i++)
			{
				let key = keys[i];

				if (key === 'email' && !vm.validEmail)
				{
					return true;
				}
				else if (vm.contact[key].length === 0)
				{
					return true;
				}
			}

			return false;
		},


        onlyStKruis()
        {
            let bp = new Date('1 Augustus 2024 00:00:00'),
                date = new Date();

            return date >= bp;
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


		validEmail()
		{
			return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(vm.contact.email));
		},


		vars()
		{
			return Object.keys(vm.page).length > 0 ? vm.page.variables : {};
		},
	},


	methods: {
		async submit()
		{
			vm.submitted = true;

			if (process.env.NODE_ENV == 'development')
			{
				setTimeout(() => {
					vm.success = false;
					vm.processed = true;
				}, 3000);
			}
			else
			{
				let formData = new FormData();

				for (let i = 0; i < vm.files.length; i++)
				{
					let f = vm.files[i];

					formData.append('files[' + i + ']', f);
				}

				formData.append('action', 'contact');
				formData.append('data', JSON.stringify(vm.contact));

				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					vm.processed = true;
					vm.success = response.data.success;
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


        getDays(loc)
        {
            let bp = new Date('1 June 2024 00:00:00'),
                date = new Date(),
                days = date >= bp ? [
                    '(ma & di)',
                    '(wo t/m za)',
                ] : [
                    '(ma, di, do & vr)',
                    '(wo & za)',
                ];

            return days[loc];
        },


		handleFilesChange()
		{
			vm.files = vm.$refs.files.files;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
