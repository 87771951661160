import { mapGetters } from 'vuex';

var vm;

export const pageMixin = {
	data()
	{
		return {
			width: 0,
		};
	},


	computed: {
		...mapGetters([
			'pages',
		]),


		hasImage()
		{
			if (Object.keys(vm.page).length > 0)
			{
				if (Object.prototype.hasOwnProperty.call(vm.page.variables, 'image') && vm.page.variables.image.full != '') return true;
				if (Object.prototype.hasOwnProperty.call(vm.page, 'bannerImage') && vm.page.variables.bannerImage.full != '') return true;
			}

			return false;
		},


		page()
		{
			let parts = vm.$route.path.split('/');
			let alias = parts[parts.length - 1];

			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];
				let path = vm.$route.path != '/' ? '/' + p.path : p.path;

				if (path == vm.$route.path || p.alias == alias)
				{
                    // Update document title
                    let separator = ' | ',
                        parts = document.title.split(separator),
                        name = parts.length > 1 ? parts[parts.length - 1] : 'Wizzard Tattoo';

                    document.title = [p.seotitle, name].join(separator);
                    
					return p;
				}
			}

			return {};
		},
	},


	methods: {
		backgroundImage(img)
		{
			let url = '';
			let keys = Object.keys(img);


			// Loop through all breakpoints
			for (let i = 0; i < keys.length; i++)
			{
				let key = keys[i];

				// Skip non-numeric keys
				if (isNaN(key)) continue;

				if (vm.width < parseInt(key))
				{
					url = img[key];
					break;
				}
			}


			// If no match smaller match was found, use the full image
			if (url === '')
			{
				url = img['full'];
			}


			return "background-image: url('" + url + "')";
		},


		getWidth()
		{
			vm.width = window.innerWidth;
		},
	},


	created()
	{
		vm = this;


		// Get the width initially
		vm.getWidth();


		// Add a resize listener
		window.addEventListener('resize', vm.getWidth);
	},


    mounted()
    {
        vm.$emit('updated');
    },
}
