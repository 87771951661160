// import Vue from 'vue';
// import VueMeta from 'vue-meta';

// Vue.use(VueMeta, {
// 	// optional pluginOptions
// 	refreshOnceOnNavigation: true
// });


import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { VueMasonryPlugin } from "vue-masonry/src/masonry.plugin";
import mitt from 'mitt'

const emitter = mitt()

let app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(store).use(router).use(VueMasonryPlugin).mount('#app')