<template>
	<div class="modal" @click.self="$router.go(-1)">

		<transition name="fade" mode="out-in">
			<loader v-if="processing" />
		</transition>


		<div v-if="product !== false" class="modal-body">

			<div v-if="loggedIn" class="toolbar">
				<button @click.prevent="onDelete">
					<i class="fas fa-times"></i>

					Verwijderen
				</button>
			</div>

			<div class="product-info" v-if="isPainting">
				<!-- <picture @mousemove="onHover"> -->
                <picture>
					<source
						v-for="key in breakpoints"
						:media="'(min-width: ' + key + 'px)'"
						:srcset="product.image[key]"
						:sizes="'(min-width: ' + key + 'px) 100vw'"
						:key="key"
					>
					<img v-if="product.image.hasOwnProperty(640)" :src="product.image[640]" :alt="product.name" />
				</picture>


				<div v-if="loggedIn && editData !== false">
					<ul class="form">
						<li>
							<label>
								Naam
							</label>

							<input type="text" v-model="editData.name" placeholder="Naam/titel" />
						</li>
						<li>
							<label>
								Breedte (in cm)
							</label>

							<input type="number" min="0" step="1" v-model="editData.width" placeholder="Breedte" />
						</li>
						<li>
							<label>
								Hoogte (in cm)
							</label>

							<input type="number" min="0" step="1" v-model="editData.height" placeholder="Hoogte" />
						</li>
						<li>
							<label>
								Prijs (in euro's)
							</label>

							<input type="number" min="0" step="1" v-model="editData.price" placeholder="Prijs" />
						</li>
						<li>
							<label>
								Is verkocht
							</label>

							<input type="radio" v-model="editData.sold" value="1" id="edit_sold_1" />

							<label for="edit_sold_1">
								Ja
							</label>

							<input type="radio" v-model="editData.sold" value="0" id="edit_sold_2" />

							<label for="edit_sold_2">
								Nee
							</label>
						</li>
						<li>
							<button @click.prevent="save">
								<i class="fas fa-download"></i>
								Opslaan
							</button>
						</li>
					</ul>
				</div>

				<div v-else>
					<h2>
						{{ product.name }}
					</h2>

					<h4>
						Categorie
					</h4>

					<p>
						{{ product.type }}
					</p>

					<h4>
						Afmetingen
					</h4>

					<p>
						{{ product.width }}cm x {{ product.height }}cm
					</p>

					<h4 v-if="!product.sold">
						Prijs
					</h4>

					<p v-if="!product.sold">
						&euro;{{ price }}
					</p>

					<img src="/img/sold.png" v-if="product.sold" class="sold-stamp"/>

				</div>
			</div>

			<div class="product-image" v-else>
				<picture>
					<source
						v-for="key in breakpoints"
						:media="'(min-width: ' + key + 'px)'"
						:srcset="product[key]"
						:sizes="'(min-width: ' + key + 'px) 100vw'"
						:key="key"
					>
					<img v-if="product.hasOwnProperty(640)" :src="product[640]" alt="" />
				</picture>
			</div>

		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loggedInMixin } from '../mixins/loggedInMixin';

import Loader from '../components/LoaderElement.vue';

var vm;

export default {
	mixins: [
		loggedInMixin,
	],


	components: {
		Loader,
	},


	data()
	{
		return {
			editData: false,
			processing: false,
		};
	},


	computed: {
		...mapGetters([
			'paintings',
			'tattoos',
		]),


		breakpoints()
		{
			let sizes = [];

			if (typeof(vm.product) == 'object')
			{
				let keys = vm.isPainting ? Object.keys(vm.product.image) : Object.keys(vm.product);

				for (let i = 0; i < keys.length; i++)
				{
					let k = keys[i];

					if (!isNaN(k))
					{
						sizes.push(k);
					}
				}

				sizes.reverse();
			}

			return sizes;
		},


		isPainting()
		{
			return vm.$route.name == 'Painting' || vm.$route.name == 'JoyArtPainting';
		},


		price()
		{
			// If there's no decimals, leave it as it is
			if (vm.product.price === Math.round(vm.product.price)) return vm.product.price;


			// Otherwise, format it
			let p = Math.round(vm.product.price * 100).toString().split('');
			p.splice(-2, 0, ',');


			return p.join('');
		},


		product()
		{
			if (vm.isPainting)
			{
				for (let i = 0; i < vm.paintings.length; i++)
				{
					let p = vm.paintings[i];

					if (p.id == vm.$route.params.id)
					{
						vm.editData = p;

						return p;
					}
				}
			}
			else if (vm.tattoos.length > vm.$route.params.id)
			// else if (vm.tattoos[vm.$route.params.category].length > vm.$route.params.id)
			{
				let p = vm.tattoos[vm.$route.params.id];
				// let p = vm.tattoos[vm.$route.params.category][vm.$route.params.id];
				vm.editData = p;

				return p;
			}

			return false;
		},
	},


	methods: {
		...mapActions([
			'deleteProduct',
			'updatePainting',
		]),


		getPictureElement(e)
		{
			let el = e.target;

			while (el.tagName.toLowerCase() !== 'picture')
			{
				el = el.parentNode;
			}

			return el;
		},


		onDelete()
		{
			vm.processing = true;

			if (vm.isPainting)
			{
				vm.deleteProduct({
					product: vm.product.id,
					type: 'paintings',
				})
				.then(() => {
					vm.processing = false;
					vm.$router.go(-1);
				});
			}
			else
			{
				vm.deleteProduct({
					product: vm.product,
					type: 'tattoos',
				})
				.then(() => {
					vm.processing = false;
					vm.$router.go(-1);
				});
			}
		},


		// onHover(e)
		// {
		// 	let el = vm.getPictureElement(e);

		// 	let startX = e.pageX - el.offsetLeft,
		// 		startY = e.pageY - el.offsetTop;

		// 	let	xRatio = startX / el.clientWidth,
		// 		yRatio = startY / el.clientHeight;

		// 	let	scrollX = Math.round(xRatio * el.scrollLeftMax),
		// 		scrollY = Math.round(yRatio * el.scrollTopMax);

		// 	el.scrollLeft = scrollX;
		// 	el.scrollTop = scrollY;
		// },


		save()
		{
			vm.processing = true;

			vm.updatePainting(vm.editData)
			.then(() => {
				vm.processing = false;
			});
		},
	},


	created()
	{
		vm = this;
	},


	watch: {
		editData(to)
		{
            if (to !== false && Object.prototype.hasOwnProperty.call(to, 'sold'))
			{
				if (to.sold === true) to.sold = 1;
				else if (to.sold === false) to.sold = 0;
			}
		},
	}
}
</script>
