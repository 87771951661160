<template>
    <main>

        <transition name="fade" mode="out-in">
            <loader v-if="processing" />
        </transition>

        <section>

            <h1>
                {{ page.pagetitle }}
            </h1>


            <div v-if="loggedIn" class="media-form">
                <form enctype="multipart/form-data">
                    <input type="file" id="files" ref="files" multiple @change="handleFilesChange" />

                    <button @click.prevent="uploadFiles">
                        <i class="fas fa-plus"></i>

                        Foto's toevoegen
                    </button>
                </form>
            </div>


            <div v-if="tattoos.length > 0" v-masonry transition-duration="0.39s" item-selector=".preview"
                class="masonry" :origin-top="true" :horizontal-order="false" :key="'gallery-' + tattoos.length">

                <div v-masonry-tile v-for="(img, id) in tattoos" class="preview" :key="img[640]">
                    <router-link :to="{ name: 'Image', params: { id: id} }">
                        <img :src="img[640]" alt="" />
                    </router-link>
                </div>

            </div>

            <loader v-else />


        </section>

        <slot name="footer"></slot>

    </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loggedInMixin } from '@/mixins/loggedInMixin';
import { pageMixin } from '@/mixins/pageMixin';

import Loader from '@/components/LoaderElement.vue';

var vm;

export default {
	mixins: [
		loggedInMixin,
        pageMixin,
    ],


	components: {
		Loader,
	},


	data()
	{
		return {
			additional: 20,
			allLoaded: false,
			files: '',
			limit: 20,
			processing: false,
		};
	},


	computed:
	{
		...mapGetters([
			// 'categories',
			'tattoos',
		]),
	},


	methods: {
		...mapActions([
			'addTattoos',
		]),


		handleFilesChange()
		{
			vm.files = vm.$refs.files.files;
		},


		// onScroll(e)
		// {
		// 	let container = e.target;
		//
		// 	while (container.nodeName.toUpperCase() !== 'HTML')
		// 	{
		// 		container = container.parentNode;
		// 	}
		//
		// 	if (window.scrollY + container.clientHeight > container.scrollHeight - (window.innerHeight * 0.4))
		// 	{
		// 		vm.limit += vm.additional;
		// 	}
		// },


		uploadFiles()
		{
			vm.processing = true;

			let formData = new FormData();

			for (let i = 0; i < vm.files.length; i++)
			{
				let f = vm.files[i];

				formData.append('files[' + i + ']', f);
			}

			formData.append('action', 'add');
			// formData.append('category', vm.$route.params.category);
			formData.append('type', 'tattoos');

			vm.addTattoos(formData)
			.then(() => {
				vm.processing = false;
			});
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
