import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';
// import { resolve } from "core-js/es6/promise";

var base = 'https://wizzardtattoo.nl/';

export default createStore({
	state: {
		agendaItems: {},
		base: base,
		categories: {},
        monthModifier: 0,
		pages: [],
		paintings: [],
		structure: [],
		tattoos: [],
		user: {},
	},


	getters: {
		agendaItems: state => {
			return state.agendaItems;
		},


		base: state => {
			return state.base;
		},


		categories: state => {
			return state.categories;
		},
        

		monthModifier: state => {
			return state.monthModifier;
		},


		pages: state => {
			return state.pages;
		},


		paintings: state => {
			return state.paintings;
		},


		structure: state => {
			return state.structure;
		},


		tattoos: state => {
			return state.tattoos;
		},


		user: state => {
			return state.user;
		},
	},


	mutations: {
		setCategories (state, value)
		{
			state.categories = value;
		},


		setPages (state, value)
		{
			state.pages = value;
		},


		setPaintings (state, value)
		{
			state.paintings = value;
		},


		setStructure (state, value)
		{
			state.structure = value;
		},


		setTattoos (state, value)
		{
			state.tattoos = value.images;
		},


		setUser (state, value)
		{
			state.user = value;
		},


		updateAgendaItems (state, value)
		{
			let items = JSON.parse(JSON.stringify(state.agendaItems));

            if (JSON.stringify(items[value.month]) != JSON.stringify(value.data))
            {
                items[value.month] = value.data;
    
                state.agendaItems = items;
            }
		},


        updateMonthModifier (state, value)
        {
            state.monthModifier = value;
        },


		updatePaintings (state, value)
		{
			state.paintings = value;
		},


		updateTattoos (state, value)
		{
			// let tattoos = JSON.parse(JSON.stringify(state.tattoos));

			// tattoos[value.category] = value.images;

            console.log(value);

			state.tattoos = value.images;
		},
	},


	actions: {
		async addPainting (context, payload)
		{
			let user = context.state.user;

			if (Object.prototype.hasOwnProperty.call(user, 'loggedIn') && user.loggedIn)
			{
				await axios.post('api/paintings/', payload, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					context.commit('updatePaintings', response.data);
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


		async addTattoos (context, payload)
		{
			let user = context.state.user;

			if (Object.prototype.hasOwnProperty.call(user, 'loggedIn') && user.loggedIn)
			{
				await axios.post('api/images/', payload, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					context.commit('updateTattoos', {
						category: payload.get('category'),
						images: response.data.images,
					});
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


		async deleteProduct (context, payload)
		{
			let user = context.state.user;
            
			if (Object.prototype.hasOwnProperty.call(user, 'loggedIn') && user.loggedIn)
			{
				let type = payload.type == 'tattoos' ? 'images' : payload.type;

				payload.action = 'delete';

				await axios.post('api/' + type + '/', payload)
				.then(response => {
					let mutation = payload.type == 'paintings' ? 'updatePaintings' : 'setTattoos';

					context.commit(mutation, response.data);
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


		async updatePainting (context, payload)
		{
			let user = context.state.user;

			if (Object.prototype.hasOwnProperty.call(user, 'loggedIn') && user.loggedIn)
			{
				await axios.post('api/paintings/', {
					action: 'update',
					data: payload,
				})
				.then(response => {
					console.log('response');
					console.log(response.data);
					context.commit('updatePaintings', response.data);
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


		_initialize: (context, payload) => {
			axios.get('api/initialize/', { params: payload })
			.then(response => {
				context.commit('setCategories', response.data.categories);
				context.commit('setPages', response.data.pages);
				context.commit('setStructure', response.data.structure);
				context.commit('setUser', response.data.user);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		_lazyImages: (context, payload) => {
			axios.get('api/lazy/', { params: payload })
			.then(response => {
				context.commit('setPaintings', response.data.paintings);
				context.commit('setTattoos', response.data.tattoos);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		getAgendaItems: (context, payload) => {
			let params = {
				m: payload.replace('_', '-'),
			};

			return axios.get('api/agenda/', { params: params })
			.then(response => {
				context.commit('updateAgendaItems', {
					month: payload,
					data: response.data,
				});
			});
		},


		getCategories: (context) => {
			return axios.get('api/categories/')
			.then(response => {
				context.commit('setCategories', response.data);
			});
		},


		getImages: (context) => {
			let params = {
				type: 'tattoos',
			};

			return axios.get('api/images/', { params: params })
			.then(response => {
				context.commit('setTattoos', response.data);
			});
		},


		getPage: ({state}, payload) => {
			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];

				if (payload == p.alias)
				{
					return p;
				}
			}

			return false;
		},


		getPaintings: (context) => {
			return axios.get('api/paintings/')
			.then(response => {
				context.commit('setPaintings', response.data);
			});
		},


		getStructure: (context, payload) => {
			return axios.get('api/structure/', { params: payload })
			.then(response => {
				context.commit('setStructure', response.data);
			});
		},


		getUser: (context) => {
			return axios.get('api/user/')
			.then(response => {
				context.commit('setUser', response.data);
			});
		},


		loadPages: (context, payload) => {
			axios.get('api/pages/', { params: payload })
			.then(response => {
				context.commit('setPages', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		updateAgendaItem: (context, payload) => {
			let user = context.state.user;

			if (Object.prototype.hasOwnProperty.call(user, 'loggedIn') && user.loggedIn)
			{
				let data = JSON.parse(JSON.stringify(payload));
				data.month = payload.month.replace('_', '-');

				axios.post('api/agenda/', data)
				.then(response => {
					context.commit('updateAgendaItems', {
						month: payload.month.replace('-', '_'),
						data: response.data,
					});
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},


        updateMonthModifier: (context, payload) => {
            let mm = context.state.monthModifier + payload;
            context.commit('updateMonthModifier', mm);
        },
	},
});
