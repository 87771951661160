<template>

	<figure :style="backgroundImage">
		<figcaption v-if="caption.length > 0">
			{{ caption }}
		</figcaption>
	</figure>

</template>

<script>
var vm;

export default {
	props: {
		caption: {
			type: String,
			default: '',
		},
		img: Object,
		size: {
			type: [String, Number],
			default: 640,
		},
	},


	computed: {
		backgroundImage()
		{
            let url = Object.prototype.hasOwnProperty.call(vm.img, vm.size) ? vm.img[vm.size] : vm.img['full'];

			return "background-image: url('" + url + "')";
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
