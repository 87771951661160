<template>
	<div :class="classes" @click="toggleAvailability" :data-option="option.id">
		<span>
			{{ name }}
		</span>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loggedInMixin } from '../mixins/loggedInMixin';

var vm;

export default {
    mixins: [
        loggedInMixin,
    ],


	props: {
		date: Date,
		option: Object,
	},


	computed: {
		...mapGetters([
			'agendaItems',
		]),


		appointment()
		{
			let dates = vm.agendaItems[vm.month].dates;

			let key = vm.date.toLocaleDateString('nl-NL', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});

			key = key.split('-').reverse().join('-');

			for (let i = 0; i < dates.length; i++)
			{
				let d = dates[i];

				if (d.date == key && vm.option.id == d.fk_option)
				{
					return d;
				}
			}

			return false;
		},


		classes()
		{
			let cl = ['time'];

			if (vm.appointment !== false)
			{
				cl.push('occupied');
			}

			return cl.join(' ');
		},


		month()
		{
			return [
				vm.date.getFullYear(),
				vm.date.toLocaleDateString('nl-NL', {
					month: '2-digit',
				}),
			].join('_');
		},


		name()
		{
			return (vm.date.getDay() == 6 && vm.option.id == 1) ? '12:00' : vm.option.name;
		},
	},


	methods: {
		...mapActions([
			'updateAgendaItem',
		]),


		toggleAvailability(e)
		{
            // If the user isn't logged in, they shouldn't be able to toggle
            if (!vm.loggedIn) return;


            let target = e.target;

            while (target.className.indexOf('time') < 0)
            {
                target = target.parentNode;
            }

            // Past dates can't be changed
            if (target.parentNode.className.indexOf('past') >= 0) return;

			let agenda = vm.agendaItems[vm.month],
				agendaItem = false,
				day = false,
				n = target.parentNode.attributes['data-n'].nodeValue,
				option = false;


			for (let i = 0; i < agenda.options.length; i++)
			{
				let o = agenda.options[i];

				if (o.id == target.attributes['data-option'].nodeValue)
				{
					option = o;
				}
			}


			for (let i = 0; i < agenda.dates.length; i++)
			{
				let d = agenda.dates[i];

				if (d.day == n)
				{
					day = d;

					if (d.fk_option == option.id)
					{
						agendaItem = d;
					}
				}
			}

			let date = day !== false ? day.date : new Date(vm.month.replace('_', '-') + '-' + n).toLocaleDateString('nl-NL',  {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).split('-').reverse().join('-');


			// Construct the confirmation question
			let q = [
				'Weet je zeker dat je deze tijd (',
				option.name,
				' op ',
				date.split('-').reverse().join('-'),
				') wilt aanpassen naar ',
				agendaItem !== false ? 'BESCHIKBAAR' : 'BEZET',
				'?',
			];

			if (confirm(q.join('')))
			{
				if (agendaItem === false)
				{
					agendaItem = {
						date: date,
						fk_option: option.id,
					};
				}

				vm.updateAgendaItem({
					item: agendaItem,
					month: vm.month,
				})
			}
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
