<template>

	<section v-if="Object.keys(page).length > 0" :style="backgroundImage(page.variables.bannerImage)" class="banner">

		<div class="banner-content">

			<div v-html="page.variables.bannerText" />

			<div v-if="cta !== false" class="toolbar">
				<a :href="cta.url" :target="cta.target" :class="buttonClasses(cta)">
					<i :class="cta.icon"></i>

					{{ cta.text }}
				</a>
			</div>

		</div>
	</section>

	<section v-else>
		loading...
	</section>

</template>

<script>
import { pageMixin } from '@/mixins/pageMixin';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	props: {
		cta: {
			type: [Boolean, Object],
			default: false,
		},

		ctas: {
			type: Array,
			default: () => [],
		},
	},


	computed: {
		columnCount()
		{
			let cc = 1,
				vars = vm.page.variables;

            if (Object.prototype.hasOwnProperty.call(vars, 'bannerText2') && vars.bannerText2.length > 0) cc++;
            if (Object.prototype.hasOwnProperty.call(vars, 'bannerText3') && vars.bannerText3.length > 0) cc++;

			return '--columns: ' + cc;
		},
	},


	methods: {
		buttonClasses(cta)
		{
			let cl = [
                'button',
            ];

            if (Object.prototype.hasOwnProperty.call(cta, 'color')) cl.push(cta.color);

			return cl.join(' ');
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
