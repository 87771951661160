import { mapGetters } from 'vuex';

var vm;

export const loggedInMixin = {
	computed: {
		...mapGetters([
			'user',
		]),


		loggedIn()
		{
			return process.env.NODE_ENV == 'production' ? vm.user.loggedIn : true;
		},
	},


	created()
	{
		vm = this;
	},
}
