<template>
    <main>

        <transition name="fade" mode="out-in">
            <loader v-if="processing" />
        </transition>

        <section>

            <h1>
                <router-link v-if="$route.meta.type == 'joyart'" :to="{ name: 'JoyArtOverview' }">
                    JoyArt
                </router-link>

                <router-link v-else :to="{ name: 'PaintingOverview' }">
                    Schilderijen
                </router-link>

                <i class="fas fa-caret-right fa-xs"></i>

                {{ title }}
            </h1>

            <div v-if="loggedIn" class="media-form">
                <form enctype="multipart/form-data">
                    <ul class="form">
                        <li>
                            <label for="files">
                                Afbeelding
                            </label>

                            <input type="file" id="files" ref="files" multiple @change="handleFilesChange" />
                        </li>
                        <li>
                            <label>
                                Naam
                            </label>

                            <input type="text" v-model="painting.name" placeholder="Naam/titel" />
                        </li>
                        <li>
                            <label>
                                Breedte (in cm)
                            </label>

                            <input type="number" min="0" step="1" v-model="painting.width" placeholder="Breedte" />
                        </li>
                        <li>
                            <label>
                                Hoogte (in cm)
                            </label>

                            <input type="number" min="0" step="1" v-model="painting.height" placeholder="Hoogte" />
                        </li>
                        <li>
                            <label>
                                Prijs (in euro's)
                            </label>

                            <input type="number" min="0" step="1" v-model="painting.price" placeholder="Prijs" />
                        </li>
                        <li>
                            <label>
                                Is verkocht
                            </label>

                            <input type="radio" v-model="painting.sold" value="1" id="sold_1" />

                            <label for="sold_1">
                                Ja
                            </label>

                            <input type="radio" v-model="painting.sold" value="0" id="sold_2" />

                            <label for="sold_2">
                                Nee
                            </label>
                        </li>
                    </ul>

                    <button @click.prevent="uploadFiles" :disabled="!validForm">
                        <i class="fas fa-plus"></i>

                        Schilderij toevoegen
                    </button>
                </form>
            </div>

            <div v-if="gallery.length > 0" v-masonry transition-duration="0.39s" item-selector=".preview"
                class="masonry" :origin-top="true" :horizontal-order="false">

                <div v-masonry-tile v-for="(painting, id) in gallery" class="preview" :key="id">
                    <router-link
                        :to="{ name: $route.meta.type == 'joyart' ? 'JoyArtPainting' : 'Painting', params: { id: painting.id} }"
                        :class="painting.sold ? 'sold' : 'for-sale'">
                        <img :src="painting.image[640]" alt="" />
                    </router-link>
                </div>

            </div>

            <loader v-else />

        </section>

        <slot name="footer"></slot>
    </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loggedInMixin } from '@/mixins/loggedInMixin';
import { pageMixin } from '@/mixins/pageMixin';

import Loader from '@/components/LoaderElement.vue';

var vm;

export default {
	mixins: [
		loggedInMixin,
		pageMixin,
	],


	components: {
		Loader,
	},


	data()
	{
		return {
			additional: 20,
			allLoaded: false,
			painting: {
				artist: this.$route.meta.type,
				height: '',
				name: '',
				price: '',
				sold: 0,
				width: '',
			},
			files: '',
			limit: 20,
			processing: false,
		};
	},


	computed:
	{
		...mapGetters([
			'categories',
			'paintings',
		]),


		gallery()
		{
			let result = [];

			for (let i = 0; i < vm.paintings.length; i++)
			{
				let p = vm.paintings[i];

				if (p.type == vm.$route.params.category && p.artist == vm.$route.meta.type)
				{
					result.push(p);
				}
			}

			return result;
		},


		title()
		{
			for (let i = 0; i < vm.categories.length; i++)
			{
				let c = vm.categories[i];

				if (c.type == vm.$route.meta.type && c.alias == vm.$route.params.category)
				{
					return c.title;
				}
			}

			return false;
		},


		validForm()
		{
			if (vm.files == '') return false;

			let keys = Object.keys(vm.painting);

			for (let i = 0; i < keys.length; i++)
			{
				let key = keys[i];

				if (key === 'sold') continue;

				if (vm.painting[key] == '' || vm.painting[key] == 0) return false;
			}

			return true;
		},
	},


	methods: {
		...mapActions([
			'addPainting',
		]),


		handleFilesChange()
		{
			vm.files = vm.$refs.files.files;
		},


		uploadFiles()
		{
			if (vm.validForm)
			{
				vm.processing = true;

				let formData = new FormData();

				for (let i = 0; i < vm.files.length; i++)
				{
					let f = vm.files[i];

					formData.append('files[' + i + ']', f);
				}

				formData.append('action', 'add');
				formData.append('category', vm.$route.params.category);
				formData.append('type', 'paintings');
				formData.append('fk_category', vm.page.id);

				let keys = Object.keys(vm.painting);

				for (let i = 0; i < keys.length; i++)
				{
					let key = keys[i];
					formData.append(key, vm.painting[key]);
				}

				vm.addPainting(formData)
				.then(() => {
					vm.processing = false;
				});
			}
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
