<template>

    <div class="toolbar">
        <router-link :to="{ name: 'Contact' }" class="button tertiary">
            Neem contact op
        </router-link>

        <a href="mailto:info@wizzardtattoo.nl" class="button">
            Mail direct
        </a>
    </div>
    
</template>