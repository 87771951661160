<template>
	<footer>

		<nav>
			<router-link v-for="p in nav" :to="toPath(p)" :key="'footer-' + p.alias">
				{{ p.pagetitle }}
			</router-link>

			<a href="http://www.veiligtatoeerenenpiercen.nl/" target="_blank">
				GGD gecertificeerd
			</a>
		</nav>


		<div class="footer">
			<div>
				<span>
					&copy; {{ year }}

					<strong>
						Wizzard Tattoo
					</strong>
				</span>
			</div>

			<div>
				<span>
					Realisatie:
				</span>

				<a href="https://www.phaedos.com/" target="_blank">
					Phaedos
				</a>
			</div>
		</div>

	</footer>
</template>

<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	computed: {
		...mapGetters([
			'pages',
		]),


		nav()
		{
			let nav = [];

			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];

				if (p.parent == 0 && p.hidemenu)
				{
					nav.push(p);
				}
			}

			return nav;
		},


		year()
		{
			return new Date().getFullYear();
		},
	},


	methods: {
		toPath(page)
		{
			let p = page.path;

			if (p.substr(0, 1) != '/') p = '/' + p;

			if (p.substr(-1) == '/') p = p.substr(0, p.length - 1);

			return p;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
