<template>
	<header :class="showNav ? 'open' : ''">
		<span class="toggle" @click="lockScroll">
            <em>
                menu
            </em>
			<span></span>
			<span></span>
			<span></span>
		</span>

		<nav>
			<router-link :to="{ name: 'Home' }" exact>
                Home
            </router-link>

			<router-link v-for="item in nav" :to="to(item)" :key="[item.alias, item.id].join('-')" v-html="item.name" />
		</nav>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	props: {
		isPaintings: Boolean,
		showNav: Boolean,
	},


	computed: {
		...mapGetters([
			'structure',
		]),


		logo()
		{
			return vm.isPaintings ? 'img/logo-alt.svg' : 'img/logo.svg';
		},


		nav()
		{
			let nav = [];

			for (let i = 0; i < vm.structure.length; i++)
			{
				let item = vm.structure[i];

				if (item.alias !== 'home' && item.parent == 0 && item.hidemenu == 0)
				{
					nav.push(item);
				}
			}

			return nav;
		},
	},


	methods: {
		lockScroll()
		{
			vm.$emit('locked', !vm.showNav);
		},


		to(item)
		{
			let to;

			switch (parseInt(item.template))
			{
				case 2:
					to = {
						name: 'Home',
					};
					break;

				case 3:
					to = {
						name: item.alias == 'schilderijen' ? 'PaintingGallery' : 'TattooGallery',
						params: {
							alias: item.alias,
						},
					};
					break;

				case 4:
					to = {
						name: 'Contact',
					};
					break;

				case 5:
					to = {
						name: item.alias == 'schilderijen' ? 'PaintingOverview' : 'TattooGallery',
					};
					break;

				case 6:
					to = {
						name: 'Agenda'
					};
					break;

				default:
					to = {
						name: 'Page',
						params: {
							alias: item.alias,
						},
					};
					break;
			}

			return to;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
