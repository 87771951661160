<template>
    <main v-if="Object.keys(page).length > 0">

        <banner :cta="cta" />


        <section :class="galleryClass">

            <router-link v-for="(cat, alias) in previews" :to="{ name: routeName, params: { category: alias } }"
                class="preview image" :key="[$route.meta.type, alias].join('-')">
                <div class="wrapper">
                    <app-figure class="grayscale" :img="cat.image" :caption="cat.title" />
                </div>
            </router-link>

        </section>


        <section v-if="page.content != ''" class="white floating-image">

            <div :class="imgClasses" v-if="page.variables.image != ''">
                <div class="wrapper">
                    <app-figure class="grayscale" :img="page.variables.image" />
                </div>
            </div>

            <div class="content">
                <div v-html="page.content" />

                <div v-if="$route.meta.type == 'tattoos'" class="toolbar">
                    <router-link :to="{ name: 'Agenda' }" class="button">
                        Kijk in de agenda
                    </router-link>

                    <router-link :to="{ name: 'Contact' }" class="button secondary">
                        Neem contact op
                    </router-link>
                </div>
            </div>

        </section>

        <slot name="footer"></slot>
    </main>
</template>

<script>
import { mapGetters } from 'vuex';

import { pageMixin } from '@/mixins/pageMixin';

import AppFigure from '@/components/AppFigure.vue';
import Banner from '@/components/BannerSection.vue';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	data()
	{
		return {
			joyartCTA: {
				icon: 'fab fa-facebook-f',
				target: '_blank',
				text: 'Meer op Facebook',
				url: 'https://www.facebook.com/joyartvlissingen/',
			},
			socialCTA: {
				icon: 'fab fa-facebook-f',
				target: '_blank',
				text: 'Meer op Facebook',
				url: 'https://www.facebook.com/Paintings-by-Ron-Pihl-100422134828865',
			},
		};
	},


	components: {
		AppFigure,
		Banner,
	},


	computed:
	{
		...mapGetters([
			'categories',
			'pages',
			'paintings',
			'tattoos',
		]),


		cta()
		{
			let cta = false;

			switch (vm.$route.meta.type)
			{
				case 'joyart':
					cta = vm.joyartCTA;
					break;

				case 'schilderijen':
					cta = vm.socialCTA;
					break;
			}

			return cta;
		},


		galleryClass()
		{
			let cl = ['gallery'];

			if (vm.$route.meta.type == 'schilderijen')
			{
				cl.push('tertiary');
			}
			else if (vm.$route.meta.type == 'joyart')
			{
				cl.push('joyart');
			}
			else
			{
				cl.push('primary');
			}

			return cl.join(' ');
		},


		imgClasses()
		{
			let cl = [
				'image',
				vm.page.variables.imgRatio,
				vm.page.variables.imgPosition,
			];

			return cl.join(' ');
		},


		page()
		{
			let routePath = vm.$route.path;

			if (routePath.substr(-1) != '/')
			{
				routePath += '/';
			}

			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];

				if ('/' + p.path == routePath)
				{
					return p;
				}
			}

			return {};
		},


		prefix()
		{
			let prefix;

			switch (vm.$route.meta.type)
			{
				case 'joyart':
					prefix = 'JoyArt';
					break;

				case 'schilderijen':
					prefix = 'Painting';
					break;

				default:
					prefix = 'Tattoo';
					break;
			}

			return prefix;
		},


		previews()
		{
			let tp = {},
				parent;

			switch (vm.prefix)
			{
				case 'JoyArt':
					parent = 36;
					break;

				case 'Painting':
					parent = 9;
					break;

				default:
					parent = 8;
					break;
			}

			let cats = vm.$route.name == 'TattooOverview' ? vm.categories : JSON.parse(JSON.stringify(vm.categories)).reverse();


			for (let i = cats.length - 1; i >= 0 ; i--)
			{
				let cat = cats[i];


				if (cat.type != vm.$route.meta.type) continue;


				for (let j = 0; j < vm.pages.length; j++)
				{
					let p = vm.pages[j];

					if (p.template == 3 && p.alias == cat.alias && p.parent == parent)
					{
						tp[cat.alias] = {
							image: p.variables.image,
							title: cat.title,
						};
					}
				}
			}


			return tp;
		},


		routeName()
		{
			return vm.prefix + 'Gallery';
		},


		title()
		{
			return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
