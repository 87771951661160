<template>
	<div v-if="isOpen" class="day" :class="isPast ? 'past' : ''">
		<div class="date" :class="location" v-if="showDate">
			{{ day }}
		</div>

		<agenda-date-time
			v-for="opt in options"
			:key="['day', day, opt.position].join('-')"
			:date="date"
			:option="opt"
		/>

	</div>

	<div v-else class="day closed" :class="isPast ? 'past' : ''">
		<div class="date">
			{{ day }}
		</div>

		<div class="time closed"></div>
	</div>
</template>

<script>
import { loggedInMixin } from '@/mixins/loggedInMixin';

import AgendaDateTime from '@/components/AgendaDateTime.vue';

var vm;

export default {
	mixins: [
		loggedInMixin,
	],


	components: {
		AgendaDateTime,
	},


	props: {
		date: Date,
		options: Array,
        showDate: {
            type: Boolean,
            default: true,
        },
	},


	computed: {
		day()
		{
			return vm.date.toLocaleDateString('nl-NL', {
				day: 'numeric',
				month: 'long',
			});
		},


		isOpen()
		{
			return vm.date.getDay() >= 0 && vm.date.getDay() < 1 ? false : true;
		},


		isPast()
		{
            // Get the current timestamp
            let today = new Date();

            // Make sure the times are the same, so it's only measuring by the dates
            today.setMilliseconds(vm.date.getMilliseconds());
            today.setSeconds(vm.date.getSeconds());
            today.setMinutes(vm.date.getMinutes());
            today.setHours(vm.date.getHours());

            return vm.date < today;
		},


        location()
        {
            let days = vm.date.getMonth() >= 5 || vm.date.getFullYear() > 2024 ? [3, 4, 6] : [3, 6],
                loc = days.indexOf(vm.date.getUTCDay()) >= 0 ? 'st-kruis' : 'oost-souburg';
            
            if (vm.date.getFullYear() == 2024 && vm.date.getMonth() == 7)
            {
                loc = 'st-kruis';
            }
            else if (vm.date.getFullYear() == 2024 && vm.date.getMonth() == 6)
            {
                loc = 'oost-souburg';
            }
            else if ((vm.date.getFullYear() == 2024 && vm.date.getMonth() > 7) || vm.date.getFullYear() > 2024)
            {
                loc = '';
            }

            return loc;
        },
	},


	created()
	{
		vm = this;
	},
}
</script>
