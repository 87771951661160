<template>
    <section class="hero">
        <div class="content" v-html="page.variables.bannerText" />
    </section>
</template>


<script>
export default {
    props: {
        page: Object,
    },
}
</script>