import { createRouter, createWebHistory } from "vue-router";

import AgendaPage from '@/views/AgendaPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import DefaultPage from '@/views/DefaultPage.vue';
import ImageGallery from '@/views/ImageGallery.vue';
import GalleryOverview from '@/views/GalleryOverview.vue';
import HomePage from '@/views/HomePage.vue';
import PaintingsGallery from '@/views/PaintingsGallery.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },

    {
        path: '/agenda',
        name: 'Agenda',
        component: AgendaPage,
    },

    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
    },

    // {
    // 	path: '/tattoos',
    // 	name: 'TattooOverview',
    // 	component: GalleryOverview,
    // 	meta: {
    // 		type: 'tattoos',
    // 	},
    //     children: [
    // 		{
    // 			path: '/tattoos/:category/:id',
    // 			name: 'Image',
    // 			components: {
    // 				product: Product,
    // 			},
    // 			meta: {
    // 				showModal: true,
    // 			},
    // 		},
    // 	]
    // },

    {
        path: '/tattoos',
        // path: '/tattoos/:category',
        name: 'TattooGallery',
        component: ImageGallery,
        meta: {
            showModal: false,
        },
        children: [
            {
                path: '/tattoos/:id',
                name: 'Image',
                meta: {
                    showModal: true,
                },
            },
        ]
    },

    {
        path: '/schilderijen',
        name: 'PaintingOverview',
        component: GalleryOverview,
        meta: {
            type: 'schilderijen',
        }
    },

    {
        path: '/schilderijen/:category',
        name: 'PaintingGallery',
        component: PaintingsGallery,
        meta: {
            showModal: false,
            type: 'schilderijen',
        },
        children: [
            {
                path: '/schilderijen/:category/:id',
                name: 'Painting',
                meta: {
                    showModal: true,
                    type: 'schilderijen',
                },
            },
        ]
    },

    {
        path: '/joyart',
        name: 'JoyArtOverview',
        component: GalleryOverview,
        meta: {
            type: 'joyart',
        }
    },

    {
        path: '/joyart/:category',
        name: 'JoyArtGallery',
        component: PaintingsGallery,
        meta: {
            showModal: false,
            type: 'joyart',
        },
        children: [
            {
                path: '/joyart/:category/:id',
                name: 'JoyArtPainting',
                meta: {
                    showModal: true,
                    type: 'joyart',
                },
            },
        ]
    },

    {
        path: '/:alias',
        name: 'Page',
        component: DefaultPage,
    },
];


const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (
            to.name != 'Image' && from.name != 'Image' &&
            to.name != 'Painting' && from.name != 'Painting' &&
            to.name != 'JoyArtPainting' && from.name != 'JoyArtPainting'
        )
        {
            return savedPosition ? savedPosition : { top: 0 };
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;